import ApiService from "@/service/ApiService";

export default function useApi() {
  const SearchAppeal = async (data) => {
    return await ApiService.post("search_appeal", data);
  };

  const UpdateLocation = async (data) => {
    return await ApiService.put("update_location_appeal/" + data.id, data);
  };

  return {
    SearchAppeal,
    UpdateLocation,
  };
}
