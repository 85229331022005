<script setup>
import { onMounted, inject, ref } from "vue";
import { Field } from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import useApi from "@/service/api/location";
import useSweetalert from "@/service/sweetalert2";
import moment from "moment";
import { SET_ID } from "@/store/modules/AppealModel";

const store = inject("store");
const router = inject("router");
const id = ref();
const { SearchAppeal } = useApi();
const { SconfirmNc } = useSweetalert();
const data = ref();
let isLoading = ref(false);
let color = ref(process.env.VUE_APP_COLOR);

onMounted(() => {
  if (!(store.getters.getAccessToken.accesstoken && store.getters.getProfire.is_admin)) {
    router.push({ name: "Home" });
  }
  store.dispatch(SET_ID, "");
});

const UpdateLocation = (id) => {
  store.dispatch(SET_ID, id);
  router.push({ name: "Location_Map" });
};

const search = () => {
  if (id.value) {
    data.value = "";
    isLoading.value = true;
    let da = {
      id: Number(id.value),
    };
    SearchAppeal(da)
      .then((result) => {
        isLoading.value = false;
        if (result.data.status === "Success") {
          data.value = result.data.data;
        } else if (result.data.status === "No Ticket") {
          SconfirmNc("ไม่พบใบงาน", "info", "ตกลง");
        } else {
          SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(() => {
            //
          });
        }
      })
      .catch((error) => {
        isLoading.value = false;
        if (error.response) {
          SconfirmNc(
            `เกิดข้อผิดผาด ${error.response.request.status} กรุณาลองใหม่อีกครั้ง`,
            "error",
            "ตกลง"
          );
        }
      });
  } else {
    SconfirmNc("กรุณากรอกเลข Ticket", "info", "ตกลง");
  }
};

function dateThai(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");
}

const back = () => {
  router.push({ name: "Dashboard" });
};
</script>
<template>
  <div class="row">
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">อัปเดตโลเคชั่น</div>
        </div>

        <div class="card-body mt-2 white">
          <loading
            v-model:active="isLoading"
            :can-cancel="false"
            :is-full-page="true"
            :color="color"
            loader="dots"
          />
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >เลข Ticket</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  type="number"
                  name="id"
                  class="form-control form-control-md form-control-solid"
                  placeholder="00000"
                  v-model="id"
                />
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <div class="row mb-6">
              <div class="col-lg-8 fv-row"></div>
              <div class="col-lg-4 fv-row">
                <button style="float: right" @click="search" class="btn btn-success">
                  ค้นหา <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
          <!--end::Card body-->
        </div>
        <div class="mb-3 mb-47" v-if="data">
          <div class="card-body mt-3 white">
            <div class="history">
              <div class="history-header">
                <div class="history-title">{{ data.title }}</div>
                <div class="history-status">{{ data.status }}</div>
              </div>
              <div class="history-body">
                <div class="history-image">
                  <img :src="data.icon" class="history-image-logo" alt="" />
                </div>
                <div class="history-detail">{{ data.detail }}</div>
                <div class="history-botton">
                  <button
                    type="button"
                    @click="UpdateLocation(data.id)"
                    class="btn see-more"
                  >
                    อัปเดตโลเคชั่น
                  </button>
                </div>
              </div>

              <div class="history-footer">
                {{ dateThai(data.created_at) }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <div style="width: 100%; padding: 0px 5px">
              <button type="button" @click="back" class="button btn-back">
                ย้อนกลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bi {
  color: white;
  font-size: 16px;
  float: left;
  margin-top: 0;
  margin-right: 2px;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
