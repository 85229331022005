<template>
  <div class="row">
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">รับเรื่องร้องทุกข์</div>
        </div>
        <Form
          @submit="onSubmitAppeal"
          :validation-schema="appeal"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >หัวข้อเรื่อง : {{ data_appeal.title }}</label
                >
                <!--end::Label-->

                <!--begin::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6" v-if="data_appeal.title == 'อื่นๆ'">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อเรื่องย่อย</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="sub_title"
                    class="form-control form-control-md form-control-solid"
                    placeholder="หัวข้อเรื่องย่อย"
                    v-model="data_appeal.sub_title"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="sub_title" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6" v-else>
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >หัวข้อเรื่องย่อย</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="sub_title"
                    class="form-control form-control-md form-control-solid"
                    as="select"
                    v-model="data_appeal.sub_title"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="sub_title in sub_titlesList"
                      :key="sub_title"
                      :value="sub_title.name"
                    >
                      {{ sub_title.name }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="sub_title" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >รายละเอียด</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    as="textarea"
                    rows="6"
                    name="detail"
                    class="form-control form-control-md form-control-solid"
                    v-model="data_appeal.detail"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="detail" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >แนบรูป (ไม่เกิน 3 รูป)</label
                >
                <!--end::Label-->

                <!--begin::Col-->

                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                  <div
                    v-if="imageUrl.length !== 0"
                    class="imagePreviewWrapper"
                    style="padding: 15px 0px"
                  >
                    <div class="row">
                      <div
                        style="position: relative"
                        class="col-4"
                        v-for="(item, index) in imageUrl"
                        :key="index"
                      >
                        <div class="xx" @click="deleteImage(index)">
                          <span>x</span>
                        </div>
                        <img class="images" :src="item" />
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="getFilecamera"
                    multiple="multiple"
                    accept="image/jpg, image/jpeg ,image/png"
                    @input="pickFile"
                    style="display: none"
                    capture="camera"
                  />
                  <input
                    type="file"
                    id="getFile"
                    multiple="multiple"
                    accept="image/jpg, image/jpeg, image/png"
                    @input="pickFile"
                    style="display: none"
                  />
                  <div class="d-flex justify-center align-center">
                    <div style="width: 100%; padding: 0px 5px">
                      <button
                        type="button"
                        @click="selectImage(1)"
                        class="form-control btn btn-outline-secondary"
                      >
                        ถ่ายรูป<img
                          src="@/assets/camera.png"
                          class="icon-ima"
                        />
                      </button>
                    </div>
                    <div style="width: 100%; padding: 0px 5px">
                      <button
                        type="button"
                        @click="selectImage(2)"
                        class="form-control btn btn-outline-secondary"
                      >
                        แนบรูป<img
                          src="@/assets/picture.png"
                          class="icon-ima"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ถัดไป
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, computed, ref } from "vue";
import { SET_APPEAL } from "@/store/modules/AppealModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { SconfirmNc } = useSweetalert();
    const data_appeal = store.getters.getAppeal;
    const sub_titles = store.getters.getSub_Title;
    const imageUrl = ref([]);
    const appeal = Yup.object().shape({
      sub_title: Yup.string()
        .required("กรุณาเลือก หัวข้อเรื่องย่อย")
        .label("หัวข้อเรื่องย่อย"),
      detail: Yup.string().required("กรุณากรอก รายละเอียด").label("รายละเอียด"),
    });
    if (data_appeal.imageUrl) {
      imageUrl.value = data_appeal.imageUrl;
    }

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const sub_titlesList = computed(() => {
      return sub_titles.filter(
        (sub_title) => sub_title.title === data_appeal.title
      );
    });

    const onSubmitAppeal = (values) => {
      store.dispatch(SET_APPEAL, values);
      router.push({ name: "Appeal_1" });
    };

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 3) {
        if (imageUrl.value.length < 3) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 3 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 3 รูป", "error", "ตกลง");
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      onSubmitAppeal,
      appeal,
      data_appeal,
      sub_titlesList,
      back,
      selectImage,
      pickFile,
      imageUrl,
      deleteImage,
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}
.icon {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}
.icon-ima {
  width: 30px;
  margin-left: 5px;
}
.btn-outline-secondary {
  border: 1px solid var(--color) !important;
}
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
</style>
