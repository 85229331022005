<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> รับเรื่องร้องทุกข์</span
          ><button
            @click="gotoLocation"
            v-if="store.getters.getProfire.is_admin"
            style="float: right"
            class="btn btn-success"
          >
            อัปเดตโลเคชั่น
          </button>
        </div>
        <AppealTitles></AppealTitles>
        <Appeals></Appeals>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, inject } from "vue";
import Header from "@/components/Header";
import AppealTitles from "@/components/Appeal/AppealTitle";
import Appeals from "@/components/Appeal/Appeal";

const store = inject("store");
const router = inject("router");

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
});

const gotoLocation = () => {
  router.push({ name: "Location" });
};
</script>

<style scoped></style>
