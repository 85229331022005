<script setup>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { onMounted, inject, ref } from "vue";
import { Form, Field } from "vee-validate";
import useApi from "@/service/api/location";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
import leaflet from "leaflet";
import liff from "@line/liff";

let mymap;
const store = inject("store");
const router = inject("router");
const { Sconfirm, SconfirmNc } = useSweetalert();
const { UpdateLocation } = useApi();
let isLoading = ref(false);
let color = ref(process.env.VUE_APP_COLOR);
let latitude = ref("");
let longitude = ref("");
let marker = ref([]);

const appeal = Yup.object().shape({
  latitude: Yup.string(),
  longitude: Yup.string(),
});

function setLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      latitude.value = position.coords.latitude;
      longitude.value = position.coords.longitude;
      let newLatLng = new leaflet.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      marker.value.setLatLng(newLatLng);
      mymap.panTo(newLatLng);
    });
  } else {
    alert("Geolocation is not supported by your browser");
  }
}

onMounted(() => {
  if (!(store.getters.getAccessToken.accesstoken && store.getters.getProfire.is_admin)) {
    router.push({ name: "Home" });
  }
  setLocation();

  mymap = leaflet
    .map("mapid")
    .setView([process.env.VUE_APP_LAT, process.env.VUE_APP_LNG], 15);
  leaflet
    .tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
      {
        attribution: "bacos25",
        maxZoom: 18,
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
      }
    )
    .addTo(mymap);
  marker.value = leaflet.marker([process.env.VUE_APP_LAT, process.env.VUE_APP_LNG], {
    draggable: true,
  });

  marker.value.on("dragend", function (event) {
    latitude.value = event.target._latlng.lat;
    longitude.value = event.target._latlng.lng;
  });

  marker.value.addTo(mymap);
});

const mylo = () => {
  navigator.geolocation.getCurrentPosition((position) => {
    let newLatLng = new leaflet.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    marker.value.setLatLng(newLatLng);
    mymap.panTo(newLatLng);
  });
};

const onSubmitAppeal = (values) => {
  let data = {
    id: store.getters.getId,
    latitude: values.latitude,
    longitude: values.longitude,
  };
  Sconfirm("ยืนยัน การอัปเดต", "question")
    .then((result) => {
      if (result.isConfirmed) {
        isLoading.value = true;
        UpdateLocation(data).then((result) => {
          isLoading.value = false;
          if (result.data.status === "success") {
            SconfirmNc("การอัปเดตสำเร็จ", "success", "ตกลง").then(() => {
              router.push({ name: "Location" });
            });
          } else {
            SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(() => {
              liff.ready.then(() => {
                liff.closeWindow();
              });
            });
          }
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
      }
    });
};

const back = () => {
  router.push({ name: "Location" });
};
</script>
<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">
            อัปเดตตำแหน่งปัญหา <br />
            ใบงานที่ {{ store.getters.getId }}
          </div>
        </div>
        <Form
          @submit="onSubmitAppeal"
          :validation-schema="appeal"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row mb-6">
                <div id="mapid" style="height: 450px"></div>
                <Field type="hidden" name="latitude" v-model="latitude" />
                <Field type="hidden" name="longitude" v-model="longitude" />
                <div>
                  <div class="myl" @click="mylo">
                    ตำแหน่งของฉัน<i class="bi bi-geo-fill" style="margin-left: 10px"></i>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>

          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">อัปเดต</button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding: 0;
}
.myl {
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 17px;
}
@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
</style>
